import React from 'react';
import { Card, CardContent, Typography, Box, Chip } from '@mui/material';
import { TrendingUp as TrendingUpIcon, TrendingDown as TrendingDownIcon, TrendingFlat as TrendingFlatIcon } from '@mui/icons-material';


interface MetricCardProps {
  title: string;
  value: number | null;
  trend?: number;
  unit?: string;
}


const MetricCard: React.FC<{ title: string; value: number | null; trend?: number; unit?: string }> = ({ 
    title, 
    value, 
    trend,
    unit 
  }) => {
    if (value === null) return null;
  
    const getTrendIcon = (trend?: number) => {
      if (!trend) return <TrendingFlatIcon />;
      return trend > 0 ? <TrendingUpIcon /> : <TrendingDownIcon />;
    };
  
    const getTrendColor = (trend?: number): "success" | "error" | "default" => {
      if (!trend) return "default";
      return trend > 0 ? "success" : "error";
    };
    
    return (
      <Card variant="outlined" sx={{ height: '100%' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h5">
              {value.toFixed(3)} {unit || ''}
            </Typography>
            {trend !== undefined && (
              <Chip
                icon={getTrendIcon(trend)}
                label={`${Math.abs(trend).toFixed(1)}%`}
                color={getTrendColor(trend)}
                size="small"
              />
            )}
          </Box>
        </CardContent>
      </Card>
    );
  };

export { MetricCard };
export type { MetricCardProps };
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../config/firebase_config';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  collection, 
  query, 
  orderBy, 
  limit, 
  getDocs,
  QueryDocumentSnapshot 
} from 'firebase/firestore';
import { getStorage, ref, listAll } from 'firebase/storage';
import { HRVMetrics, HRVResult } from '../types/HRVTypes';
import { 
  Card, 
  CardContent, 
  Typography, 
  Grid, 
  Box,
  Chip,
} from '@mui/material';
import { 
  TrendingUp as TrendingUpIcon, 
  TrendingDown as TrendingDownIcon,
  TrendingFlat as TrendingFlatIcon 
} from '@mui/icons-material';
import { HRVMetricsDisplay } from './HRVMetricsDisplay';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface UserData {
  email: string;
  displayName?: string;
  // Add other user fields as needed
}

interface HRVSummary {
  meanHR: number;
  sdnn: number;
  rmssd: number;
  pnn50: number;
  recordingDate: string;
  recordingLength: number;
  dataType: string;
  fileName: string[];
}

interface HRVRecord {
  id: string;
  userId: string;
  hrvSummary: HRVSummary;
  hrv_output: HRVResult;
  createdAt: string;
  dataType: string;
  samplingRate: number;
  // Add other fields as needed
}

const dashboardColors = {
  primary: '#1976d2',
  secondary: '#2196f3',
  success: '#4caf50',
  background: {
    main: '#f8f9fa',
    paper: '#ffffff',
    highlight: '#f3f8ff',
  },
  text: {
    primary: '#2c3e50',
    secondary: '#546e7a',
  },
  chart: {
    sdnn: {
      stroke: '#1976d2',
      fill: 'rgba(25, 118, 210, 0.1)',
    },
    rmssd: {
      stroke: '#4caf50',
      fill: 'rgba(76, 175, 80, 0.1)',
    },
  },
};

const Dashboard: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [userFiles, setUserFiles] = useState<any[]>([]);
  const [hrvRecords, setHrvRecords] = useState<HRVRecord[]>([]);
  const [latestRecord, setLatestRecord] = useState<HRVRecord | null>(null);
  const [selectedRecord, setSelectedRecord] = useState<HRVRecord | null>(null);

  useEffect(() => {
    if (user) {
      fetchUserData();
      fetchUserFiles();
      fetchHRVRecords();
    }
  }, [user]);

  const fetchUserData = async (): Promise<void> => {
    if (!user) return;
    
    const db = getFirestore();
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (userDoc.exists()) {
      setUserData(userDoc.data() as UserData);
    }
  };

  const fetchUserFiles = async (): Promise<void> => {
    if (!user) return;

    const storage = getStorage();
    const listRef = ref(storage, `user-files/${user.uid}`);
    const res = await listAll(listRef);
    setUserFiles(res.items);
  };

  const fetchHRVRecords = async (): Promise<void> => {
    if (!user) return;

    const db = getFirestore();
    const hrvRef = collection(db, 'hrv_records');
    const q = query(
      hrvRef,
      orderBy('createdAt', 'desc'),
      limit(10)
    );
    
    const querySnapshot = await getDocs(q);
    const records: HRVRecord[] = [];
    
    querySnapshot.forEach((doc: QueryDocumentSnapshot) => {
      records.push({
        id: doc.id,
        ...doc.data()
      } as HRVRecord);
    });

    setHrvRecords(records);
    if (records.length > 0) {
      setLatestRecord(records[0]);
    }
  };

  const prepareChartData = () => {
    return hrvRecords
      .slice() // Create a copy
      .reverse() // Show oldest to newest
      .map(record => ({
        date: new Date(record.createdAt).toLocaleDateString(),
        SDNN: record.hrv_output["0"].HRV_SDNN,
        RMSSD: record.hrv_output["0"].HRV_RMSSD,
      }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!user) return <div>Please sign in to view this page</div>;

  return (
    <div className="container" style={{ backgroundColor: dashboardColors.background.main, padding: '24px' }}>
      <Typography variant="h4" sx={{ 
        color: dashboardColors.text.primary, 
        mb: 3,
        fontWeight: 600 
      }}>
        Welcome, {user.displayName}
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card elevation={2} sx={{ 
            height: '100%',
            backgroundColor: dashboardColors.background.paper,
            borderRadius: 2,
            transition: 'all 0.3s ease',
            '&:hover': {
              boxShadow: 6,
            }
          }}>
            <CardContent>
              <Typography variant="h5" sx={{ 
                color: dashboardColors.text.primary,
                fontWeight: 600,
                mb: 2 
              }}>
                Recording Details
              </Typography>
              {selectedRecord ? (
                <>
                  <Typography variant="body1">
                    Recorded: {new Date(selectedRecord.createdAt).toLocaleString()}
                  </Typography>
                  <Typography variant="body1">
                    Data Type: {selectedRecord.dataType}
                  </Typography>
                  <HRVMetricsDisplay metrics={selectedRecord.hrv_output["0"]} />
                  <Box sx={{ 
                    mt: 4, 
                    bgcolor: dashboardColors.background.highlight, 
                    p: 3, 
                    borderRadius: 2,
                    boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
                  }}>
                    <Typography variant="h6" gutterBottom sx={{ color: '#333' }}>
                      HRV Trends
                    </Typography>
                    <Box sx={{ 
                      width: '100%', 
                      height: 250, 
                      p: 2,
                      '& .recharts-tooltip-wrapper': {
                        outline: 'none',
                      },
                    }}>
                      <ResponsiveContainer>
                        <LineChart 
                          data={prepareChartData()}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <XAxis 
                            dataKey="date" 
                            stroke="#666"
                            tick={{ fill: '#666', fontSize: 12 }}
                          />
                          <YAxis 
                            stroke="#666"
                            tick={{ fill: '#666', fontSize: 12 }}
                          />
                          <Tooltip 
                            contentStyle={{
                              backgroundColor: 'rgba(255, 255, 255, 0.95)',
                              border: 'none',
                              borderRadius: '8px',
                              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                              padding: '10px',
                            }}
                          />
                          <Line 
                            type="monotone" 
                            dataKey="SDNN" 
                            stroke={dashboardColors.chart.sdnn.stroke}
                            strokeWidth={2}
                            fill={dashboardColors.chart.sdnn.fill}
                            dot={{ 
                              stroke: dashboardColors.chart.sdnn.stroke, 
                              strokeWidth: 2,
                              r: 4,
                              fill: '#fff'
                            }}
                            activeDot={{ 
                              stroke: dashboardColors.chart.sdnn.stroke,
                              strokeWidth: 2,
                              r: 6,
                              fill: '#fff'
                            }}
                            name="SDNN"
                          />
                          <Line 
                            type="monotone" 
                            dataKey="RMSSD" 
                            stroke={dashboardColors.chart.rmssd.stroke}
                            strokeWidth={2}
                            fill={dashboardColors.chart.rmssd.fill}
                            dot={{ 
                              stroke: dashboardColors.chart.rmssd.stroke, 
                              strokeWidth: 2,
                              r: 4,
                              fill: '#fff'
                            }}
                            activeDot={{ 
                              stroke: dashboardColors.chart.rmssd.stroke,
                              strokeWidth: 2,
                              r: 6,
                              fill: '#fff'
                            }}
                            name="RMSSD"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      gap: 2, 
                      mt: 1 
                    }}>
                      <Chip 
                        label="SDNN"
                        size="small"
                        sx={{ 
                          bgcolor: dashboardColors.chart.sdnn.fill,
                          color: dashboardColors.chart.sdnn.stroke,
                          borderColor: dashboardColors.chart.sdnn.stroke,
                          border: 1,
                        }}
                      />
                      <Chip 
                        label="RMSSD"
                        size="small"
                        sx={{ 
                          bgcolor: dashboardColors.chart.rmssd.fill,
                          color: dashboardColors.chart.rmssd.stroke,
                          borderColor: dashboardColors.chart.rmssd.stroke,
                          border: 1,
                        }}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <Typography variant="body1" sx={{ color: dashboardColors.text.secondary }}>
                  Select a recording from the list to view details
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card elevation={2} sx={{ 
            backgroundColor: dashboardColors.background.paper,
            borderRadius: 2,
            transition: 'all 0.3s ease',
            '&:hover': {
              boxShadow: 6,
            }
          }}>
            <CardContent>
              <Typography variant="h5" sx={{ 
                color: dashboardColors.text.primary,
                fontWeight: 600,
                mb: 2 
              }}>
                Recent Recordings
              </Typography>
              {hrvRecords.map((record) => (
                <Box 
                  key={record.id} 
                  sx={{ 
                    mb: 2, 
                    p: 2.5,
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.08)',
                    borderRadius: 2,
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    bgcolor: selectedRecord?.id === record.id ? 
                      dashboardColors.background.highlight : 
                      dashboardColors.background.paper,
                    '&:hover': {
                      bgcolor: dashboardColors.background.highlight,
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                    }
                  }}
                  onClick={() => setSelectedRecord(record)}
                >
                  <Typography variant="subtitle1" sx={{ 
                    color: dashboardColors.text.primary,
                    fontWeight: 500 
                  }}>
                    {new Date(record.createdAt).toLocaleString()}
                  </Typography>
                  <Typography variant="body2" sx={{ 
                    color: dashboardColors.text.secondary,
                    mb: 1 
                  }}>
                    {record.dataType}
                  </Typography>
                  <Chip 
                    size="small"
                    label={`SDNN: ${record.hrv_output["0"].HRV_SDNN}`}
                    sx={{ 
                      mr: 1,
                      bgcolor: dashboardColors.chart.sdnn.fill,
                      color: dashboardColors.chart.sdnn.stroke,
                      fontWeight: 500
                    }}
                  />
                  <Chip 
                    size="small"
                    label={`RMSSD: ${record.hrv_output["0"].HRV_RMSSD}`}
                    sx={{ 
                      bgcolor: dashboardColors.chart.rmssd.fill,
                      color: dashboardColors.chart.rmssd.stroke,
                      fontWeight: 500
                    }}
                  />
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
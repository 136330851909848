import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

type HRVResult = {
  segment: number;
  data: {
    HRV_MeanNN: number | null;
    HRV_SDNN: number | null;
    HRV_RMSSD: number | null;
    HRV_pNN50: number | null;
    HRV_LFHF: number | null;
    HRV_HF: number | null;
    HRV_LF: number | null;
    HRV_SD1: number | null;
    HRV_SD2: number | null;
    HRV_CSI: number | null;
    HRV_CVI: number | null; 
  };
};

interface HRVVisualizationProps {
  results: HRVResult[];
}

const HRVVisualization: React.FC<HRVVisualizationProps> = ({ results }) => {
  const segments = results.map((result) => `Segment ${result.segment}`);
  const meanNN = results.map((result) => result.data.HRV_MeanNN);
  const sdnn = results.map((result) => result.data.HRV_SDNN);
  const rmssd = results.map((result) => result.data.HRV_RMSSD);
  const lf = results.map((result) => result.data.HRV_LF);
  const hf = results.map((result) => result.data.HRV_HF);
  const lfHfRatio = results.map((result) => result.data.HRV_LFHF);

  const lineChartData = {
    labels: segments,
    datasets: [
      {
        label: 'SDNN (ms)',
        data: sdnn,
        borderWidth: 1,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
      {
        label: 'RMSSD (ms)',
        data: rmssd,
        borderWidth: 1,
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
    ],
  };

  const barChartData = {
    labels: segments,
    datasets: [
        {
            label: 'LF (ms²)',
            data: lf,
            fill: false,
            backgroundColor: 'rgba(54, 162, 235, 0.5)', // Bar color (semi-transparent)
            borderColor: 'rgba(54, 162, 235, 1)', // Border color
            borderWidth: 1, // Border thickness
            tension: 0.1,
        },
        {
            label: 'HF (ms²)',
            data: hf,
            fill: false,
            backgroundColor: 'rgba(153, 102, 255, 0.5)', // Bar color
            borderColor: 'rgba(153, 102, 255, 1)', // Border color
            borderWidth: 1,
            tension: 0.1,
        },
        {
            label: 'LF/HF Ratio',
            data: lfHfRatio,
            fill: false,
            backgroundColor: 'rgba(255, 159, 64, 0.5)', // Bar color
            borderColor: 'rgba(255, 159, 64, 1)', // Border color
            borderWidth: 1,
            tension: 0.1,
        },
    ],
};

  return (
    <div>
      <h2>HRV Results Visualization</h2>
      <div style={{ width: '100%', maxWidth: '600px', margin: 'auto' }}>
        <h3>Time-Domain Metrics</h3>
        <Line data={lineChartData} />
      </div>
      <div style={{ width: '100%', maxWidth: '600px', margin: 'auto', marginTop: '2rem' }}>
        <h3>Frequency-Domain Metrics</h3>
        <Bar data={barChartData} />
      </div>
    </div>
  );
};


const parseDataToJson = (inputString: string): any => {
  if (inputString.startsWith('data: ')) {
    inputString = inputString.substring(6);
  }
  try {
    inputString = inputString.replace(/nan/g, '0')
    .replace(/Infinity/g, '0')
    .replace(/NaN/g, '0')
    .replace(/'/g, '"')
    .replace(/inf/g, '0')
    .replace(/-inf/g, '0')
    .replace(/True/g, 'true')
    .replace(/False/g, 'false')
    .replace(/None/g, 'null');
    console.log('Input String:', inputString);
    return JSON.parse(inputString);
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    return { progress: 0 };
  }
};

const convertResult = async (data: any): Promise<HRVResult[]> => {
    let res: any; // Declare a variable to hold the parsed result
  
    console.log('Raw Data:', data);
  
    // Check if `data.out` exists and is a string
    if (data.out) {
      try {
        const jsonString : string = '{ "res": ' + data.out + '}';
        console.log('JSON String:', jsonString);
        res = parseDataToJson(jsonString).res; // Parse into an object/array
    } catch (error) {
        console.error('Error parsing  JSON:', error);
        return []; // Return an empty array if parsing fails
      }
    } else {
      res = data; // If no `out`, use `data` itself
    }
  
    console.log('Processed Res:', res);
  
    // If `res` is an array, map it to extract segments
    if (Array.isArray(res)) {
      console.log('Array:', res);
      return res.map((segment) => ({
        segment: segment.segment || 0, // Use 0 as default if "segment" is missing
        data: {
          HRV_MeanNN: segment.data?.HRV_MeanNN || null,
          HRV_SDNN: segment.data?.HRV_SDNN || null,
          HRV_RMSSD: segment.data?.HRV_RMSSD || null,
          HRV_pNN50: segment.data?.HRV_pNN50 || null,
          HRV_LFHF: segment.data?.HRV_LFHF || null,
          HRV_HF: segment.data?.HRV_HF || null,
          HRV_LF: segment.data?.HRV_LF || null,
          HRV_SD1: segment.data?.HRV_SD1 || null,
          HRV_SD2: segment.data?.HRV_SD2 || null,
          HRV_CSI: segment.data?.HRV_CSI || null,
          HRV_CVI: segment.data?.HRV_CVI || null,
        },
      }));
    }
  
    // If `res` is an object, process its keys (less common case)
    if (typeof res === 'object' && res !== null) {
      console.log('Object Entries:', Object.entries(res));
      return Object.entries(res).map(([key, value]: [string, any]) => ({
        segment: parseInt(key, 10) || 0, // Convert key to number or default to 0
        data: {
          HRV_MeanNN: value?.HRV_MeanNN || null,
          HRV_SDNN: value?.HRV_SDNN || null,
          HRV_RMSSD: value?.HRV_RMSSD || null,
          HRV_pNN50: value?.HRV_pNN50 || null,
          HRV_LFHF: value?.HRV_LFHF || null,
          HRV_HF: value?.HRV_HF || null,
          HRV_LF: value?.HRV_LF || null,
          HRV_SD1: value?.HRV_SD1 || null,
          HRV_SD2: value?.HRV_SD2 || null,
          HRV_CSI: value?.HRV_CSI || null,
          HRV_CVI: value?.HRV_CVI || null,
        },
      }));
    }
  
    // Unsupported format
    console.error('Unsupported data format for HRV results.', res);
    return [];
  };
  
  

export { HRVVisualization, convertResult };
export type { HRVResult };

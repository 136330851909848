import React from 'react';
import { Grid, Typography, Card, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import heartRateMonitor from '../images/hear_rate_monitor_1_5.png';
import hrvAnalysis from '../images/heart_rate_live_1_5.png';
import flutterLibrary from '../images/flutter_1_5.png';
import sdk from '../images/sdk_1_5.png';

interface ProductItem {
  title: string;
  description: string;
  link: string;
  image: string;
}

const ProductGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  gap: '20px',
  padding: '20px',
  overflowX: 'auto',
  '& .MuiGrid-item': {
    flex: '1 0 240px',
  }
}));

const ProductCard = styled(Card)(({ theme }) => ({
  width: '100%',
  minWidth: '240px',
  borderRadius: '10px',
  backgroundColor: '#fff',
  height: '280px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    transform: 'scale(1.02)',
    transition: 'transform 0.2s',
  },
}));

const CardImage = styled('div')({
  height: '66%',
  width: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const StyledCardContent = styled('div')({
  padding: '16px',
  height: '34%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
});

const productItems: ProductItem[] = [
  { 
    title: 'Heart rate monitor', 
    description: 'Polar H10 is supported', 
    link: '/hrv-calculation-demo', 
    image: heartRateMonitor
  },
  { 
    title: 'HRV analysis', 
    description: 'Preprocess your HRV data live demo', 
    link: 'https://pub.dev/packages/flutter_hrv', 
    image: hrvAnalysis
  },
  { 
    title: 'Flutter library', 
    description: 'Flutter library to analyze HRV', 
    link: 'https://github.com/Open-HRV/Open-HRV-SDK', 
    image: flutterLibrary
  },
  { 
    title: 'Software development kit', 
    description: 'Demo of SDK based on HRV engine', 
    link: '/live-monitoring', 
    image: sdk
  },
];

const OurProducts: React.FC = () => {
  return (
    <div>
      <Typography 
        variant="h4" 
        align="center" 
        gutterBottom 
        sx={{ 
          mb: 3,
          fontWeight: 'bold',
          fontSize: { xs: '1.5rem', sm: '2rem' },
          fontFamily: 'Lato, sans-serif',
        }}
      >
        Our Products
      </Typography>
      <ProductGrid container>
        {productItems.map((product, index) => (
          <Grid item key={index}>
            <ProductCard>
              <CardImage style={{ backgroundImage: `url(${product.image})` }} />
              <StyledCardContent>
                <Typography 
                  variant="h6" 
                  component="div"
                  sx={{ 
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                    fontFamily: 'Lato, sans-serif',
                    mb: 0.5
                  }}
                >
                  {product.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ 
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                  }}
                >
                  {product.description}
                </Typography>
                {product.link.startsWith('http') ? (
                  <Button 
                    size="small" 
                    component="a" 
                    href={product.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ 
                      mt: 'auto',
                      fontSize: '0.8rem',
                      fontFamily: 'Lato, sans-serif',
                    }}
                  >
                    Learn More
                  </Button>
                ) : (
                  <Button 
                    size="small" 
                    component={RouterLink} 
                    to={product.link}
                    sx={{ 
                      mt: 'auto',
                      fontSize: '0.8rem',
                      fontFamily: 'Lato, sans-serif',
                    }}
                  >
                    Learn More
                  </Button>
                )}
              </StyledCardContent>
            </ProductCard>
          </Grid>
        ))}
      </ProductGrid>
    </div>
  );
};

export default OurProducts;

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { HRVMetrics } from '../types/HRVTypes';
import { MetricCard, MetricCardProps } from './MetrircCard';




const HRVMetricsDisplay: React.FC<{ metrics: HRVMetrics; previousMetrics?: HRVMetrics }> = ({ 
    metrics, 
    previousMetrics 
  }) => {
    const calculateTrend = (current: number, previous?: number) => {
      if (!previous) return undefined;
      return ((current - previous) / previous) * 100;
    };
  
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>Time Domain Metrics</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard 
                  title="Mean NN" 
                  value={metrics.HRV_MeanNN} 
                  trend={calculateTrend(metrics.HRV_MeanNN, previousMetrics?.HRV_MeanNN)}
                  unit="ms" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard 
                  title="SDNN" 
                  value={metrics.HRV_SDNN} 
                  trend={calculateTrend(metrics.HRV_SDNN, previousMetrics?.HRV_SDNN)}
                  unit="ms" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard 
                  title="RMSSD" 
                  value={metrics.HRV_RMSSD} 
                  trend={calculateTrend(metrics.HRV_RMSSD, previousMetrics?.HRV_RMSSD)}
                  unit="ms" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard 
                  title="pNN50" 
                  value={metrics.HRV_pNN50} 
                  trend={calculateTrend(metrics.HRV_pNN50, previousMetrics?.HRV_pNN50)}
                  unit="%" 
                />
              </Grid>
            </Grid>
          </Grid>
  
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>Frequency Domain Metrics</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard 
                  title="LF Power" 
                  value={metrics.HRV_LF} 
                  trend={calculateTrend(metrics.HRV_LF, previousMetrics?.HRV_LF)}
                  unit="ms²" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard 
                  title="HF Power" 
                  value={metrics.HRV_HF} 
                  trend={calculateTrend(metrics.HRV_HF, previousMetrics?.HRV_HF)}
                  unit="ms²" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard 
                  title="LF/HF Ratio" 
                  value={metrics.HRV_LFHF} 
                  trend={calculateTrend(metrics.HRV_LFHF, previousMetrics?.HRV_LFHF)}
                  unit="" 
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>Non-linear Metrics</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <MetricCard 
                  title="SD1" 
                  value={metrics.HRV_SD1} 
                  trend={calculateTrend(metrics.HRV_SD1, previousMetrics?.HRV_SD1)}
                  unit="ms" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MetricCard 
                  title="SD2" 
                  value={metrics.HRV_SD2} 
                  trend={calculateTrend(metrics.HRV_SD2, previousMetrics?.HRV_SD2)}
                  unit="ms" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MetricCard 
                  title="Sample Entropy" 
                  value={metrics.HRV_SampEn} 
                  trend={calculateTrend(metrics.HRV_SampEn, previousMetrics?.HRV_SampEn)}
                  unit="" 
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

export { HRVMetricsDisplay };
import React from 'react';
import { Box, Typography, IconButton, Container } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ bgcolor: 'grey.900', color: 'white', py: 3 }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
            &copy; {new Date().getFullYear()} Open HRV. All Rights Reserved.
          </Typography>

          <Box sx={{ display: 'flex', gap: 1, mt: { xs: 2, sm: 0 } }}>
            <IconButton
              component="a"
              href="https://x.com/przemyslawmarc3"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Follow us on Twitter"
              color="inherit"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://linkedin.com/in/przemyslaw-marciniak"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Connect with us on LinkedIn"
              color="inherit"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://github.com/Przemekeke"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Explore our GitHub"
              color="inherit"
            >
              <GitHubIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

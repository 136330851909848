import React, { FC, useState } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from './index';
import {
  Container,
  Typography,
  Box,
  Button,
  Chip,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';

// Define types for dataset and props
interface Dataset {
  title: string;
  description: string;
  tags: string[];
  interpretation: string;
  comments: string[];
  download_url: string;
}

interface HRVDatasetProps {
  dataset: Dataset;
}

interface HRVDatasetListProps {
  datasets: Dataset[];
}

const HRVDataset: FC<HRVDatasetProps> = ({ dataset }) => {
  const handleDownload = () => {
    logEvent(analytics, 'Dataset download clicked', { dataset_title: dataset.title });
    window.open(dataset.download_url, '_blank');
  };

  const handleDownloadInterpretation = () => {
    logEvent(analytics, 'Dataset interpretation clicked', { dataset_title: dataset.title });
    window.open(dataset.interpretation, '_blank');
  };

  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {dataset.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {dataset.description}
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Tags:
          </Typography>
          {dataset.tags.map((tag) => (
            <Chip key={tag} label={tag} size="small" sx={{ marginRight: 0.5, marginBottom: 0.5 }} />
          ))}
        </Box>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary" onClick={handleDownload}>
          Download
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleDownloadInterpretation}>
          Interpretation
        </Button>
      </CardActions>
    </Card>
  );
};

const HRVDatasetList: FC<HRVDatasetListProps> = ({ datasets }) => {
  return (
    <Box>
      {datasets.map((dataset, index) => (
        <HRVDataset key={index} dataset={dataset} />
      ))}
    </Box>
  );
};

const datasets: Dataset[] = [
  {
    title: 'ECG with ketamine',
    description: 'This dataset contains ECG data from a patient who was administered ketamine.',
    tags: ['HRV', 'Health', 'Ketamine', 'Depression'],
    interpretation: 'https://drive.google.com/file/d/1Cjvz6yEbjUKuf1iUAhYXoSL3lomkQTA5/view?usp=drive_link',
    comments: ['Comment 1', 'Comment 2'],
    download_url: 'https://drive.google.com/file/d/1U7KmlU1scwdWtNWimojCUbBBZ1ixmNrV/view?usp=drive_link',
  },
  {
    title: 'Polar single record',
    description: 'This dataset contains HRV data from a single patient who was monitored using a Polar H10 device.',
    tags: ['HRV', 'Health', 'Exercise', 'Polar'],
    interpretation: 'https://drive.google.com/file/d/14GA_5oC4cGuZWCiSafyGWWsnnuxA3Mbu/view?usp=drive_link',
    comments: ['Comment 1'],
    download_url: 'https://drive.google.com/file/d/1ZqnnE7t2RMB-CeLiR1WqtFP_sl7tpX_S/view?usp=sharing',
  },
  {
    title: 'Daily Activity with HRV',
    description: 'This dataset contains HRV data from a patient who was monitored for daily activity.',
    tags: ['HRV', 'Exercise', 'Activity'],
    interpretation: 'https://drive.google.com/file/d/1zRokd1MfbPzJ5Je6tiS2vVTN_MQT0t56/view?usp=drive_link',
    comments: ['Comment 1'],
    download_url: 'https://drive.google.com/file/d/1j2SLlwgxKQ8dRittGCLW9POE-4JLCkDm/view?usp=drive_link',
  },
  {
    title: 'Cognitive Fatigue gamers assessment dataset',
    description: 'This dataset contains HRV data from a group of 5 gamers who were assessed for cognitive fatigue.',
    tags: ['HRV', 'Health', 'Exercise', 'Cognitive Fatigue'],
    interpretation: 'www.google.com',
    comments: ['Comment 1'],
    download_url: 'https://drive.google.com/file/d/1d_cgIoN7TADKTKyPiOqZ7rjiRYltAmaO/view?usp=sharing',
  },
];

const DatasetsPage: FC = () => {
  logEvent(analytics, 'Dataset visited');

  return (
    <div className="container">
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        HRV Datasets
      </Typography>
      <Typography variant="body1" paragraph>
        Explore various HRV datasets. Learn our documentation. Use Open HRV API for analytics.
      </Typography>
      <HRVDatasetList datasets={datasets} />
    </Container>
    </div>
  );
};

export default DatasetsPage;

import React, { useState } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from './index';
import db from './config/firebase_config';
import { collection, addDoc } from 'firebase/firestore';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';

const ContactPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email && name && message) {
      try {
        const docRef = await addDoc(collection(db, 'messages'), {
          email,
          name,
          message,
        });
        console.log('Document written with ID: ', docRef.id);
        setSuccessMessage(`Thank you, ${name}, for your message!`);
        setOpenSnackbar(true);
        setEmail('');
        setName('');
        setMessage('');
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  logEvent(analytics, 'Contact page visited');

  return (
    <div className="container">
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Contact
      </Typography>
      <Typography variant="body1" paragraph>
        Want to get some more info about the project or collaborate with us? We
        can provide you with complex solutions based on HRV.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Send us a message!
      </Typography>
      <Box component="form" onSubmit={submitHandler} noValidate>
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          type="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Message"
          variant="outlined"
          multiline
          rows={5}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <Box mt={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Send Message
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Typography variant="body2" sx={{ mt: 4 }}>
        Or send an email to: przemyslaw.piotr.marciniak@gmail.com
      </Typography>
    </Container>
    </div>
  );
};

export default ContactPage;

import React from 'react';
import ReactFlow, { Background, Controls, Edge, Node } from 'react-flow-renderer';

const DataPipelineVisualization: React.FC = () => {
  const nodes: Node[] = [
    { id: 'polar', data: { label: 'Polar Device' }, position: { x: 100, y: 100 }, type: 'input' },
    { id: 'webapp', data: { label: 'Web App' }, position: { x: 600, y: 100 }, type: 'input' },
    { id: 'rawData', data: { label: 'Raw Data' }, position: { x: 200, y: 100 } },
    { id: 'metadata', data: { label: 'Dataset Metadata' }, position: { x: 300, y: 200 } },
    { id: 'hrvFeatures', data: { label: 'HRV Features' }, position: { x: 200, y: 300 } },
    { id: 'balanced', data: { label: 'Balanced Dataset' }, position: { x: 300, y: 400 } },
    { id: 'baseline', data: { label: 'Baseline Data' }, position: { x: 400, y: 300 } },
    { id: 'predictive', data: { label: 'Predictive Models' }, position: { x: 500, y: 300 } },
    { id: 'preprocessing', data: { label: 'Preprocessing' }, position: { x: 200, y: 200 } },
    { id: 'dataGen', data: { label: 'Data Generation' }, position: { x: 200, y: 400 } },
    { id: 'modeling', data: { label: 'Data Modeling' }, position: { x: 400, y: 400 } },
    { id: 'inputs', data: { label: 'Inputs' }, position: { x: 600, y: 200 } },
    { id: 'predictions', data: { label: 'Predictions' }, position: { x: 600, y: 400 } }
  ];

  const edges: Edge[] = [
    { id: 'e1', source: 'polar', target: 'rawData' },
    { id: 'e2', source: 'rawData', target: 'preprocessing' },
    { id: 'e3', source: 'preprocessing', target: 'hrvFeatures' },
    { id: 'e4', source: 'rawData', target: 'metadata' },
    { id: 'e5', source: 'hrvFeatures', target: 'metadata' },
    { id: 'e6', source: 'hrvFeatures', target: 'dataGen' },
    { id: 'e7', source: 'dataGen', target: 'balanced' },
    { id: 'e8', source: 'balanced', target: 'baseline' },
    { id: 'e9', source: 'balanced', target: 'modeling' },
    { id: 'e10', source: 'baseline', target: 'modeling' },
    { id: 'e11', source: 'modeling', target: 'predictive' },
    { id: 'e12', source: 'webapp', target: 'inputs' },
    { id: 'e13', source: 'inputs', target: 'predictive' },
    { id: 'e14', source: 'predictive', target: 'predictions' }
  ];

  return (
    <div style={{ height: 500 }}>
      <ReactFlow nodes={nodes} edges={edges}>
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default DataPipelineVisualization; 
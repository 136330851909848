import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import '@fontsource/roboto';
import openHRVLogo from '../images/logo_1_5.png';

const FooterSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(6, 0),
  marginTop: theme.spacing(4),
  fontFamily: 'Roboto, sans-serif',
}));

const FooterLink = styled('a')(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  fontFamily: 'Roboto, sans-serif',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const SocialIcon = styled('a')(({ theme }) => ({
  color: 'inherit',
  marginLeft: theme.spacing(2),
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const HomeFooter: React.FC = () => {
  return (
    <FooterSection>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="h6" 
              gutterBottom 
              sx={{ fontFamily: 'Roboto, sans-serif' }}
            >
              ABOUT
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              <FooterLink href="/about-hrv">About HRV</FooterLink>
              <FooterLink href="/wearables-we-support">Wearables</FooterLink>
              <FooterLink href="/open-hrv-team">Team</FooterLink>
              <FooterLink href="/contact">Contact</FooterLink>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{ fontFamily: 'Roboto, sans-serif' }}
            >
              DEMOS
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              <FooterLink href="/hrv-calculation-demo">Demo</FooterLink>
              <FooterLink href="/hrv-datasets">Datasets</FooterLink>
              <FooterLink href="/dashboard">Dashboard</FooterLink>
              <FooterLink href="/live">Live Monitor</FooterLink>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{ fontFamily: 'Roboto, sans-serif' }}
            >
              MORE
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              <FooterLink href="/blog">Blog</FooterLink>
              <FooterLink href="/dataflow">Data Pipeline</FooterLink>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 4,
          pt: 4,
          borderTop: '1px solid #eaeaea'
        }}>
          <Box>
            <img src={openHRVLogo} alt="Open HRV" style={{ height: '40px' }} />
          </Box>
        </Box>
      </Container>
    </FooterSection>
  );
};

export default HomeFooter;
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, googleProvider } from './config/firebase_config';
import { signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
  Box,
  Divider,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import openHRVLogo from './images/logo_1_5.png';


const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [openDialog, setOpenDialog] = useState<'signin' | 'register' | null>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogout = () => {
    auth.signOut();
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const handleEmailSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setOpenDialog(null);
      setError('');
      navigate('/dashboard');
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleRegister = async () => {
    try {
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      await createUserWithEmailAndPassword(auth, email, password);
      setOpenDialog(null);
      setError('');
      navigate('/dashboard');
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleClose = () => {
    setOpenDialog(null);
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setError('');
  };

  return (
    <>
      {user ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar src={user?.photoURL || ''} alt={user?.displayName || 'User'} />
          <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#012B64' }}>
            {user?.displayName}
          </Typography>
          <Button 
            onClick={handleLogout} 
            sx={{ 
              color: '#012B64',
              '&:hover': {
                backgroundColor: 'rgba(1, 43, 100, 0.1)',
                color: '#023a85',
              },
            }}
          >
            Log out
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', gap: 1 }}>
          {['Sign In', 'Register', 'Sign In with Google'].map((label) => (
            <Button
              key={label}
              onClick={() => {
                if (label === 'Sign In with Google') signInWithGoogle();
                else setOpenDialog(label.toLowerCase().includes('register') ? 'register' : 'signin');
              }}
              sx={{
                color: '#012B64',
                border: '1px solid #012B64',
                '&:hover': {
                  backgroundColor: '#012B64',
                  color: 'white',
                },
              }}
            >
              {label}
            </Button>
          ))}
        </Box>
      )}

      <Dialog open={openDialog !== null} onClose={handleClose}>
        <DialogTitle>{openDialog === 'signin' ? 'Sign In' : 'Register'}</DialogTitle>
        <DialogContent>
          {error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {openDialog === 'register' && (
            <TextField
              margin="dense"
              label="Confirm Password"
              type="password"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={openDialog === 'signin' ? handleEmailSignIn : handleRegister}>
            {openDialog === 'signin' ? 'Sign In' : 'Register'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Header: React.FC = () => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [user] = useAuthState(auth);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const baseMenuItems = [
    { name: 'Home', path: '/' },
    { name: 'Live Demo', path: '/live' },
    { name: 'HRV Calculator', path: '/hrv-calculation-demo' },
    { name: 'Contact', path: '/contact' },
    { name: 'Team', path: '/open-hrv-team' },
    { name: 'Datasets', path: '/hrv-datasets' },
    { name: 'Wearables', path: '/wearables-we-support' },
    { name: 'About', path: '/about-hrv' },
  ];

  const menuItems = user 
    ? [{ name: 'Dashboard', path: '/dashboard' }, ...baseMenuItems]
    : baseMenuItems;

  return (
    <AppBar position="static" sx={{ 
      background: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Branding / Logo */}
        <Link
          to="/"
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <img
            src={openHRVLogo}
            alt="Open HRV Logo"
            style={{
              height: '32px',
              width: 'auto'
            }}
          />
        </Link>

        {/* Menu Items for Larger Screens */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
          {menuItems.map((item) => (
            <Button
              key={item.name}
              component={Link}
              to={item.path}
              sx={{
                color: '#012B64',
                '&:hover': {
                  backgroundColor: 'rgba(1, 43, 100, 0.1)',
                  color: '#023a85',
                },
                transition: 'all 0.3s ease',
              }}
            >
              {item.name}
            </Button>
          ))}
          <SignIn />
        </Box>

        {/* Hamburger Menu for Mobile Screens */}
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton
            onClick={handleMenuOpen}
            sx={{
              color: '#012B64',
              '&:hover': {
                color: '#023a85',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
            sx={{ 
              display: { xs: 'block', md: 'none' },
              '& .MuiPaper-root': {
                backgroundColor: 'white',
                color: '#012B64'
              }
            }}
          >
            {menuItems.map((item) => (
              <MenuItem
                key={item.name}
                component={Link}
                to={item.path}
                onClick={handleMenuClose}
                sx={{
                  color: '#012B64',
                  '&:hover': {
                    backgroundColor: 'rgba(1, 43, 100, 0.1)',
                    color: '#023a85',
                  },
                }}
              >
                {item.name}
              </MenuItem>
            ))}
            <Divider sx={{ backgroundColor: 'rgba(1, 43, 100, 0.1)' }} />
            <MenuItem onClick={handleMenuClose}>
              <SignIn />
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
export { SignIn };


import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import {
  Button,
  Container,
  Typography,
  Box,
  Card,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import '@fontsource/montserrat';

// Assuming these components are updated to use Material-UI as well
import FormComponent from '../SubscribeForm';
import OpenHRVFAQ from '../OpenHRVFAQ';
import { StyledHero } from './Hero';
// Remove or comment out the heroBackground import
// import heroBackground from './images/hrv-hero.png';
import OurProducts from './OurProducts';
import HomeFooter from './HomeFooter';

// Define interfaces for our data structures
interface DemoItem {
  title: string;
  description: string;
  link: string;
}

// Add this interface and component near your other interfaces
interface ButtonLinkProps {
  to: string;
  children: React.ReactNode;
  [key: string]: any;  // for other Button props
}

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>((
  { to, children, ...props }, 
  ref
) => {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <Button component={renderLink} {...props}>
      {children}
    </Button>
  );
});

const InfoSection = styled(Box)(({ theme }) => ({
  padding: '20px',
  backgroundColor: '#f7f7f7',
  width: '100%',
}));

const DemoGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '20px',
  padding: '20px',
}));

const DemoItem = styled(Card)(({ theme }) => ({
  width: '300px',
  borderRadius: '10px',
  padding: '15px',
  textAlign: 'center',
  backgroundColor: '#fff',
  height: '100px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const SurveySection = styled(Box)(({ theme }) => ({
  padding: '20px',
  textAlign: 'center',
  width: '100%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const demoItems: DemoItem[] = [
  { title: 'HRV Calculation Demo', description: 'Live Demo to preprocess your HRV data', link: '/hrv-calculation-demo' },
  { title: 'Flutter HRV', description: 'Flutter library to analyze HRV from different devices', link: 'https://pub.dev/packages/flutter_hrv' },
  { title: 'SDK', description: 'Demo of SDK based on our HRV engine', link: 'https://github.com/Open-HRV/Open-HRV-SDK' },
];

const HomePage: React.FC = () => {
  return (
    <div className='container' style={{ backgroundColor: '#f7f7f7' }}>
      <main>

        <StyledHero>
          <Container maxWidth="md">
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                gap: 4,
              }}
            >
              <Box sx={{ 
                flex: '2',
                textAlign: { xs: 'center', md: 'left' } 
              }}>
                <Typography 
                  variant="h4" 
                  color="text.primary"
                  paragraph
                  sx={{ 
                    fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                    fontWeight: 'medium',
                    fontFamily: 'Montserrat, sans-serif',
                  }}
                >
                  Understand Your{' '}
                  <Box
                    component="span"
                    sx={{
                      display: 'block',
                      backgroundColor: '#012B64',
                      color: 'white',
                      padding: '0.1em 0.3em',
                      borderRadius: '4px',
                      mt: 1,
                      fontWeight: 700,
                    }}
                  >
                    Heart's Rate Variability
                  </Box>{' '}
                  in Minutes
                </Typography>
                <Typography variant="h6" color="text.primary" paragraph>
                  What is Heart Rate Variability (HRV) and why is it important for your health? Discover how Open HRV can help you analyze and improve your heart health.
                </Typography>
                
                <Button
                  component={RouterLink}
                  to="/hrv-calculation-demo"
                  variant="contained"
                  sx={{
                    backgroundColor: '#012B64',
                    color: 'white',
                    borderRadius: '50px',
                    padding: '10px 30px',
                    fontSize: '1.1rem',
                    textTransform: 'none',
                    mt: 2,
                    '&:hover': {
                      backgroundColor: '#023d8a',
                    }
                  }}
                >
                  Analyze your HRV
                </Button>
              </Box>
              
              <Box
                component="img"
                src="/hero_logo_4.png"
                alt="Open HRV Logo"
                sx={{
                  width: '100%',
                  maxWidth: '300px',
                  flex: '1',
                  height: 'auto',
                  order: { xs: -1, md: 2 },
                }}
              />
            </Box>
          </Container>
        </StyledHero>

        <Container maxWidth="lg">
          <OurProducts />

          <Box sx={{ my: 4 }}>
            <FormComponent />
          </Box>

          <InfoSection>
            <Typography variant="body1" align="center">
              <Button 
                component="a"
                href="https://medium.com/@przemekmarciniak/what-is-heart-rate-variability-and-how-we-could-use-it-dbe46e8c2fe6"
                target="_blank"
                rel="noopener noreferrer"
                variant="text"
              >
                Read article: What is Heart Rate Variability
              </Button>
            </Typography>
          </InfoSection>

          <Box sx={{ my: 4 }}>
            <OpenHRVFAQ />
          </Box>

          <SurveySection>
            <Typography variant="body1" gutterBottom>Please help us improve and take a</Typography>
            <Button 
              variant="contained" 
              color="primary" 
              href="https://s.surveyplanet.com/64c8c7b6635d1581bfaef67d" 
              target="_blank" 
              rel="noopener noreferrer"
              sx={{ mt: 2 }}
            >
              Survey
            </Button>
          </SurveySection>
        </Container>
        <HomeFooter />
      </main>
    </div>
  );
};

export default HomePage;
import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import './App.css';
import { logEvent } from "firebase/analytics";
import { analytics } from './index.js';
import heartRateImage from './images/heart-rate.png';
import wearableImage from './images/HRV-wearable_devices.png';
import valuesImage from './images/values.png';
import './AboutPage.css';
import FormComponent from './SubscribeForm';

interface ValueProps {
  title: string;
  description: string;
}

const values: ValueProps[] = [
  {
    title: "Transparency",
    description: "We believe that the potential of biomarkers should be accessible for everyone. There is still little knowledge in the society and within the medical doctors about how it can be used."
  },
  {
    title: "Helpfulness",
    description: "We as a company shouldn't operate if we are not able to help in the treatment of any conditions. We believe in our potential but also care about the credibility and effectiveness of our product."
  },
  {
    title: "Empathy",
    description: "We are dealing sometimes with very sensitive data and working on serious medical conditions. We need to respect that and understand the value of what we are doing for others."
  },
  {
    title: "Inclusivity",
    description: "We are building an interdisciplinary team working together. We should respect everyone's background and ideas, and be curious about learning more from the perspective of others."
  },
  {
    title: "Curiosity",
    description: "While working in such an environment, it is very important to gain knowledge on a variety of topics and be responsible for your growth."
  },
  {
    title: "Freedom",
    description: "We do not want to have any constraints when it comes to place or time of working."
  },
  {
    title: "Creativity",
    description: "It is important for us to generate new ideas and share them. Our operations should embrace that."
  }
];

const ValueItem: React.FC<ValueProps> = ({ title, description }) => (
  <li>
    <img src={valuesImage} alt={`${title} icon`} className="value-icon" />
    <div className="value-details">
      <span className="value-text">{title}</span>
      <p className="value-description">{description}</p>
    </div>
  </li>
);

const HeroSection = styled('div')(({ theme }) => ({
  background: 'linear-gradient(135deg, #1976d2 0%, #0d47a1 100%)',
  color: '#fff',
  padding: theme.spacing(15, 0),
  textAlign: 'center',
  position: 'relative',
  marginBottom: theme.spacing(6),
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50px',
    background: '#fff',
    clipPath: 'polygon(0 100%, 100% 100%, 100% 0)',
  }
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: '1.5rem',
  fontWeight: 300,
  color: 'rgba(255, 255, 255, 0.9)',
}));

const AboutPage: React.FC = (): JSX.Element => {
  React.useEffect(() => {
    logEvent(analytics, 'About page visited');
  }, []);

  return (
    <>
      <HeroSection>
        <Container maxWidth="lg">
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: { xs: '2.5rem', md: '4rem' },
              fontWeight: 700,
              letterSpacing: '-0.5px',
              marginBottom: 2,
            }}
          >
            Open HRV
          </Typography>
          <SubTitle>
            Unlocking the Power of Heart Rate Variability
          </SubTitle>
          <Typography
            variant="h6"
            sx={{
              maxWidth: '800px',
              margin: '2rem auto',
              color: 'rgba(255, 255, 255, 0.9)',
              lineHeight: 1.6,
            }}
          >
            Discover how your heart's rhythm can reveal insights about your health, 
            stress levels, and overall well-being. Join us in revolutionizing 
            personal health monitoring through advanced HRV analysis.
          </Typography>
        </Container>
      </HeroSection>

      <section className="section">
        <div className="content">
          <h2>What is HRV?</h2>
          <p className="description">
            Heart Rate Variability (HRV) enables non-invasive monitoring of the activity of the autonomic nervous system. 
            The number of studies investigating HRV is constantly increasing due to the great value of this technology. 
            Generally, high HRV is related to healthy conditions, whereas low HRV is associated with the presence of pathology in our body.
          </p>
          <p className="description">
            What is important, it can be affected by our lifestyle and environmental factors. 
            Taking it into consideration, HRV might not only enable the diagnosis of pathology but also help us monitor the recovery.
          </p>
          <p className="description">
            The research shows that HRV reflects not only heart diseases. 
            The variation of time between two heartbeats may also be useful in monitoring diabetes, depression, cancer, and many other pathological conditions.
          </p>
        </div>
        <div className="image-container">
          <img src={heartRateImage} alt="Heart Rate" className="image" />
        </div>
      </section>

      <section className="section">
        <div className="content">
          <h2>Our Solution</h2>
          <p className="description">
            We are developing a seamless protocol which integrates with your wearable 
            (check wearables which we support <a href="/wearables-we-support">here</a>). 
            You can always get history of your data, add some context notes to it or if you want 
            you can share your data with our research group or other research group! 
            We are building a crowdsourcing community of users building new paradigm of research and therapy. 
            You can be a part of it!
          </p>
        </div>
        <div className="image-container">
          <img src={wearableImage} alt="Wearable" className="image" />
        </div>
      </section>

      <section className="section values-section">
        <div className="content">
          <h2>Our Values</h2>
          <ul className="values-list">
            {values.map((value, index) => (
              <ValueItem 
                key={index}
                title={value.title}
                description={value.description}
              />
            ))}
          </ul>
        </div>
      </section>

      <section className="section subscribe-section">
        <div className="content">
          <h1>Join Our Community</h1>
          <p className="description">
            Stay updated with the latest developments in HRV research and our platform. 
            Get exclusive access to early features and be part of our growing community 
            of health enthusiasts and researchers.
          </p>
          <FormComponent />
        </div>
      </section>
    </>
  );
};

export default AboutPage;
import React from 'react';
import { Button } from '@mui/material';

interface RRData {
  timestamp: string;
  rrInterval: number;
}

interface StoreDataProps {
  rrIntervalData: RRData[];
}

const StoreData: React.FC<StoreDataProps> = ({ rrIntervalData }) => {
  const handleSaveToFile = () => {
    // Create CSV headers
    const csvHeaders = 'timestamp,rrInterval\n';

    // Map data to CSV rows
    const csvRows = rrIntervalData
      .map(({ timestamp, rrInterval }) => `${timestamp},${rrInterval}`)
      .join('\n');

    // Combine headers and rows
    const csvData = csvHeaders + csvRows;

    // Create Blob and trigger download
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'RRIntervalData.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      {/* Your existing components and logic */}
      <Button variant="contained" color="primary" onClick={handleSaveToFile}>
        Save Data
      </Button>
    </div>
  );
};

export type { RRData };
export { StoreData };

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledHero = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: '60px 20px',
  width: '100%',
  minHeight: '400px',
  backgroundColor: '#f5f5f5',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

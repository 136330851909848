import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  LinearProgress,
  Grid,
} from '@mui/material';
import { getAuth } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { analytics } from './index';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import db from './config/firebase_config';
import FileSaver from 'file-saver';
import { Result } from './HRVCalculator/Result';

type HRVSummary = {
  meanHR?: number;
  sdnn?: number;
  rmssd?: number;
  pnn50?: number;
  recordingDate: string;
  recordingLength: number;
  dataType: string;
  fileName: string[];
};

export interface HRVMetrics {
  HRV_MeanNN: number;
  HRV_SDNN: number;
  HRV_SDANN1: number;
  HRV_SDNNI1: number;
  HRV_SDANN2: number | null;
  HRV_SDNNI2: number | null;
  HRV_SDANN5: number | null;
  HRV_SDNNI5: number | null;
  HRV_RMSSD: number;
  HRV_SDSD: number;
  HRV_CVNN: number;
  HRV_CVSD: number;
  HRV_MedianNN: number;
  HRV_MadNN: number;
  HRV_MCVNN: number;
  HRV_IQRNN: number;
  HRV_Prc20NN: number;
  HRV_Prc80NN: number;
  HRV_pNN50: number;
  HRV_pNN20: number;
  HRV_MinNN: number;
  HRV_MaxNN: number;
  HRV_HTI: number;
  HRV_TINN: number;
  HRV_ULF: number | null;
  HRV_VLF: number;
  HRV_LF: number;
  HRV_HF: number;
  HRV_VHF: number;
  HRV_LFHF: number;
  HRV_LFn: number;
  HRV_HFn: number;
  HRV_LnHF: number;
  HRV_SD1: number;
  HRV_SD2: number;
  HRV_SD1SD2: number;
  HRV_S: number;
  HRV_CSI: number;
  HRV_CVI: number;
  HRV_CSI_Modified: number;
  HRV_PIP: number;
  HRV_IALS: number;
  HRV_PSS: number;
  HRV_PAS: number;
  HRV_GI: number;
  HRV_SI: number;
  HRV_AI: number;
  HRV_PI: number;
  // ... add other metrics as needed
}

export interface HRVResult {
  [key: string]: HRVMetrics;
}

const HRVSingleRecordingForm: React.FC = () => {
  const [file, setFile] = useState<File | FileList | null>(null);
  const [result, setResult] = useState<any>(null);
  const [dataType, setDataType] = useState<string>('PPG');
  const [samplingRate, setSamplingRate] = useState<number>(64);
  const [segment, setSegment] = useState<boolean>(false);
  const [segmentLength, setSegmentLength] = useState<number>(60);
  const [segmentOverlap, setSegmentOverlap] = useState<number>(0.0);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [totalSegments, setTotalSegments] = useState<number>(100);
  const [user, setUser] = useState<any>(null);

  const URL = 'https://hrvwebapi-flask-76wlsdg7yq-lm.a.run.app/calculate';
  const URL_SEGMENTS = 'https://hrvwebapi-flask-76wlsdg7yq-lm.a.run.app/calculate_with_epoch';
 // const URL_SEGMENTS = 'http://127.0.0.1:8444/calculate_with_epoch';
  const URL_MULTIPLE = 'https://hrvwebapi-flask-76wlsdg7yq-lm.a.run.app/calculate_multiple_files';

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return unsubscribe;
  }, []);

  const uploadFileToStorage = async (file: File, userId: string): Promise<string> => {
    const storage = getStorage();
    const fileRef = ref(storage, `user-files/${userId}/${file.name}`);
    await uploadBytes(fileRef, file);
    return fileRef.fullPath;
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = e.target.files;
    if (uploadedFiles?.length) {
      setFile(uploadedFiles.length > 1 ? uploadedFiles : uploadedFiles[0]);
      logEvent(analytics, uploadedFiles.length > 1 ? 'Multiple files uploaded' : 'File uploaded');
    }
  };

  const parseDataToJson = (inputString: string): any => {
    if (inputString.startsWith('data: ')) {
      inputString = inputString.substring(6);
    }
    try {
      inputString = inputString.replace(/NaN/g, '0').replace(/Infinity/g, '0');
      return JSON.parse(inputString);
    } catch (error) {
      console.error('Failed to parse JSON:', error);
      return { progress: 0 };
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!file || samplingRate < 2) {
      console.error('Invalid input. Please ensure the file and sampling rate are set correctly.');
      return;
    }

    setSubmitting(true);

    const formData = new FormData();
    const url = Array.isArray(file) || file instanceof FileList
      ? URL_MULTIPLE
      : segment
      ? URL_SEGMENTS
      : URL;

    let uploadedFilePaths: string[] = [];

    if (Array.isArray(file) || file instanceof FileList) {
      for (const singleFile of Array.from(file)) {
        formData.append('files[]', singleFile, singleFile.name);
        if (user) {
          const filePath = await uploadFileToStorage(singleFile, user.uid);
          uploadedFilePaths.push(filePath);
        }
      }
    } else {
      formData.append('file', file, file.name);
      if (user) {
        const filePath = await uploadFileToStorage(file, user.uid);
        uploadedFilePaths.push(filePath);
      }
    }

    formData.append('data_type', dataType);
    formData.append('sampling_rate', samplingRate.toString());
    if (segment) {
      formData.append('segment_length', segmentLength.toString());
      formData.append('segment_overlap', segmentOverlap.toString());
    }

    try {
      const response = await fetch(url, { method: 'POST', body: formData });
      const reader = response.body
        ?.pipeThrough(new TextDecoderStream())
        .getReader();

      let latestResult: any = null;

      while (reader) {
        const { done, value } = await reader.read();
        if (done) break;
        latestResult = parseDataToJson(value);
        setProgress(latestResult.progress);
      }

      setResult(latestResult);
      const now = new Date();
      const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_${now
        .getHours()
        .toString()
        .padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now
        .getSeconds()
        .toString()
        .padStart(2, '0')}`;

      const hrvSummary: HRVSummary = {
        meanHR: latestResult?.["0"]?.HRV_MeanNN || 0,
        sdnn: latestResult?.["0"]?.HRV_SDNN || 0,
        rmssd: latestResult?.["0"]?.HRV_RMSSD || 0,
        pnn50: latestResult?.["0"]?.HRV_pNN50 || 0,
        recordingDate: now.toISOString(),
        recordingLength: Array.isArray(file) ? Array.from(file).length : 1,
        dataType: dataType,
        fileName: Array.isArray(file) || file instanceof FileList
          ? Array.from(file).map(f => f.name)
          : [file.name],
      };

      const documentId = `hrv_record_${user.uid}_${timestamp}`;
      await setDoc(doc(db, 'hrv_records', documentId), {
        userId: user.uid,
        hrvSummary,
        hrv_output: latestResult,
        createdAt: now.toISOString(),
        dataType,
        samplingRate,
        segment,
        segmentLength,
        segmentOverlap,
        filePaths: uploadedFilePaths,
      });

    } catch (error) {
      console.error('Error during calculation:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleReset = () => {
    setFile(null);
    setResult(null);
    setProgress(0);
    setSubmitting(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 600,
        margin: '0 auto',
        padding: 3,
        border: '1px solid #ccc',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h5" gutterBottom>
        HRV Single Recording Analysis
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Upload File(s)</Typography>
          <Button variant="outlined" component="label" fullWidth>
            Select Files
            <input type="file" hidden onChange={handleFileUpload} multiple />
          </Button>
          {file && (
            <Typography>
              {Array.isArray(file) || file instanceof FileList
                ? Array.from(file).map((f) => f.name).join(', ')
                : file.name}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">Data Type</Typography>
          <RadioGroup
            row
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
          >
            <FormControlLabel value="PPG" control={<Radio />} label="PPG" />
            <FormControlLabel value="ECG" control={<Radio />} label="ECG" />
            <FormControlLabel value="RRS" control={<Radio />} label="RRS" />
          </RadioGroup>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Sampling Rate (Hz)"
            type="number"
            value={samplingRate}
            onChange={(e) => setSamplingRate(Number(e.target.value))}
            fullWidth
            inputProps={{ min: 2 }}
            helperText="Must be at least 2 Hz"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={segment}
                onChange={(e) => setSegment(e.target.checked)}
              />
            }
            label="Enable Segmentation"
          />
          {segment && (
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Segment Length (seconds)"
                type="number"
                value={segmentLength}
                onChange={(e) => setSegmentLength(Number(e.target.value))}
                fullWidth
                inputProps={{ min: 1 }}
              />
              <TextField
                label="Segment Overlap (0-1)"
                type="number"
                value={segmentOverlap}
                onChange={(e) => setSegmentOverlap(Number(e.target.value))}
                fullWidth
                inputProps={{ min: 0, max: 1, step: 0.01 }}
                sx={{ mt: 2 }}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting}
            fullWidth
          >
            {submitting ? 'Calculating...' : 'Calculate'}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleReset}
            disabled={submitting}
            fullWidth
            sx={{ mt: 2 }}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      {submitting && (
        <Box sx={{ mt: 3 }}>
          <Typography>Processing...</Typography>
          <LinearProgress variant="determinate" value={(progress / totalSegments) * 100} />
          <Typography>
            {progress} / {totalSegments}
          </Typography>
        </Box>
      )}

      {result && <Result result={result} />}

    </Box>
  );
};

export default HRVSingleRecordingForm;

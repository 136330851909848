import React from 'react';
import './App.css';
import polarH10Image from './images/polar_h10.jpg';
import garminHRMImage from './images/garmin_hrm.jpg';
import FormComponent from './SubscribeForm.js';
import { logEvent } from "firebase/analytics";
import { analytics } from './index.js';
import './Wearables.css';
import { Button, Typography, Grid, Box, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

// New interfaces for better type safety
interface IntegrationStep {
  text: string;
  link?: string;
}

interface FeaturePoint {
  text: string;
}

// Component definition with React.FC type
const WearablePage: React.FC = () => {
  // Constants to manage content more efficiently
  const features: FeaturePoint[] = [
    { text: "High-precision heart rate monitoring" },
    { text: "Easy integration with our platform" },
    { text: "Real-time data syncing" },
    { text: "Advanced HRV analytics" }
  ];

  const polarSteps: IntegrationStep[] = [
    { text: "For live recording: Navigate to the Live Recording page and start monitoring your HRV in real-time.", link: "/live" },
    { text: "For uploading recorded data: Download the Polar Sensor Logger app from the Google Play Store" },
    { text: "Connect your Polar H10 device to the app" },
    { text: "Select ECG and any other data that you want to record" },
    { text: "Start recording your data. When you choose \"Save Data,\" it will automatically cache it on your device and ask you to send it to the cloud" },
    { text: "Download the file with the format: Polar_H10_XXXXXXXXXXX_ECG" },
    { text: "Upload your data to Open HRV. Set parameters Data type ECG and sample rate 130 Hz" },
    { text: "Use segmentation for better accuracy" },
    { text: "Start tracking your HRV data!" }
  ];

  const garminSteps: IntegrationStep[] = [
    { text: "Ensure your Garmin HRM is powered on and in range" },
    { text: "Navigate to the Live Recording page on Open HRV", link: "/live" },
    { text: "Pair your Garmin HRM device with Open HRV" },
    { text: "Start tracking your heart rate and HRV in real-time" }
  ];

  // Log page visit
  React.useEffect(() => {
    logEvent(analytics, 'Wearable page visited');
  }, []);

  // ... existing JSX structure remains the same, but now using the arrays ...
  return (
    <div className="wearable-container">
      <Typography variant="h3" gutterBottom className="wearable-title">
        Wearable Integration
      </Typography>
      <Typography variant="subtitle1" className="wearable-tagline">
        Connect Your Wearable with Open HRV
      </Typography>

      {/* Polar H10 Section */}
      <Grid container spacing={4} className="hero-wearable">
        <Grid item xs={12} md={6} className="hero-content">
          <Typography variant="h4" gutterBottom className="hero-title">
            New: Polar H10 Integration!
          </Typography>
          <Typography variant="body1" className="hero-description">
            We're excited to announce our latest integration with the Polar H10 device! You can now use Polar H10 not only for uploading recorded data but also for live HRV monitoring. Experience real-time insights and track your health instantly!
          </Typography>
          <Link to="/live" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary" className="cta-button">
              Start Live Recording with Polar H10
            </Button>
          </Link>
          <Button 
            href="https://play.google.com/store/apps/details?id=com.j_ware.polarsensorlogger&hl=pl" 
            variant="outlined" 
            color="primary"
            target="_blank" 
            rel="noopener noreferrer"
            className="cta-button"
            style={{ marginTop: '10px' }}
          >
            Get Polar Sensor Logger App
          </Button>
        </Grid>
        <Grid item xs={12} md={6} className="hero-image">
          <img src={polarH10Image} alt="Polar H10 Device" style={{ width: '100%' }} />
        </Grid>
      </Grid>

      {/* Garmin HRM Section */}
      <Grid container spacing={4} className="hero-wearable">
        <Grid item xs={12} md={6} className="hero-content">
          <Typography variant="h4" gutterBottom className="hero-title">
            Garmin HRM Devices Supported!
          </Typography>
          <Typography variant="body1" className="hero-description">
            We now support real-time integration with Garmin HRM devices like the HRM-Pro, HRM-Dual, and others. No additional logger app is required. Connect directly to Open HRV and start monitoring your heart rate and HRV in real-time.
          </Typography>
          <Link to="/live" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="secondary" className="cta-button">
              Start Live Recording with Garmin HRM
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} md={6} className="hero-image">
          <img src={garminHRMImage} alt="Garmin HRM Device" style={{ width: '100%' }} />
        </Grid>
      </Grid>

      <section className="feature-section">
        <Typography variant="h5" gutterBottom>
          Why Choose Polar H10 or Garmin HRM with Open HRV?
        </Typography>
        <List>
          {features.map((feature, index) => (
            <ListItem key={index}>
              <ListItemText primary={feature.text} />
            </ListItem>
          ))}
        </List>
      </section>

      <section className="integration-steps">
        <Typography variant="h5" gutterBottom>
          How to Integrate Your Polar H10
        </Typography>
        <Typography variant="body1" paragraph>
          With the Polar H10, you have two options: live recording or using the Polar Sensor Logger app to upload your data.
        </Typography>
        <ol>
          {polarSteps.map((step, index) => (
            <li key={index}>
              {step.link ? (
                <Link to={step.link}>{step.text}</Link>
              ) : (
                step.text
              )}
            </li>
          ))}
        </ol>
      </section>

      <section className="integration-steps">
        <Typography variant="h5" gutterBottom>
          How to Integrate Your Garmin HRM
        </Typography>
        <Typography variant="body1" paragraph>
          With Garmin HRM devices, simply connect them directly to Open HRV through our platform:
        </Typography>
        <ol>
          {garminSteps.map((step, index) => (
            <li key={index}>
              {step.link ? (
                <Link to={step.link}>{step.text}</Link>
              ) : (
                step.text
              )}
            </li>
          ))}
        </ol>
      </section>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Stay Updated on Our Latest Integrations!
        </Typography>
        <FormComponent />
      </Box>
    </div>
  );
};

export default WearablePage;
